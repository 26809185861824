* {
  box-sizing: border-box;
}
html,
body {
  @apply overscroll-x-none h-full m-0 text-gray-800;
}
body {
  @apply bg-gray-750 p-0 text-gray-50 text-base font-sans touch-manipulation;
}
img {
  -webkit-user-drag: element;
  user-select: none;
}

h2 {
  @apply text-lg lg:text-xl font-bold leading-tight tracking-tight md:tracking-tighter text-pretty text-slate-200;
}
#player-bar {
  @apply text-white bg-slate-400;
}
textarea,
input {
  @apply text-black;
}

.footer-links a:hover {
  @apply underline;
}
